<template>
    <o-data-lookup :data-object="dsLookupDataObject" :whereClause="getWhereClause" reloadOnWhereClauseChange>

        <template #toolbarActions>
            <div class="form-check">
                <input class="form-check-input" id="otLookup_RestrictToContext" type="checkbox" v-model="restrictedToContext" @click="toggleContext" >
                <label class="form-check-label" for="otLookup_RestrictToContext">{{ $t("Restrict to Context") }}</label>
            </div>
            <slot name="toolbarActions"></slot>
        </template>

        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.usageinterval" :ref="scope.target" style="cursor: pointer;">
                <slot name="usageinterval"></slot>
            </span>
        </template>
        
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="350"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';
    import { getOrCreateDataObject } from 'o365.vue.ts'

    const restrictedToContext = ref(true);
    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_UsageIntervalsLookup',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "Number" },
              {name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" }]
    });


    const getWhereClause = computed( () => {
        var vFilter = [];

        if (props.IdPath && restrictedToContext.value) {
            vFilter.push("'" + props.IdPath + "' LIKE IdPath + '%'");
        } 

        if (!props.IdPath) {
            vFilter.push("1=2");
        }

        return vFilter.join(" AND ");
    });
        

    function toggleContext(e,o) {
        restrictedToContext.value = !restrictedToContext.value;
    }
    
</script>